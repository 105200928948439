import React from "react"
import Layout from "../components/layout"
import PostPreview from "../components/post-preview"
import MailchimpForm from "../components/mailchimp_form"
import usePosts from "../hooks/use-posts"
import ReadLink from "../components/read-link"
import { css } from "@emotion/core"

export default () => {
  const posts = usePosts()
  return (
    <Layout>
      <ReadLink to="/about/"></ReadLink>
      <MailchimpForm />
      <div
        css={css`
          margin-right: 40px;
          padding-right: 40px;
          width: 100%;
          @media (min-width: 600px) {
            width: 80%;
          }
        `}
      >
        {posts
          .sort((a, b) => Date.parse(b.date) - Date.parse(a.date))
          .map(post => (
            <PostPreview key={post.slug} post={post} />
          ))}
      </div>
    </Layout>
  )
}
