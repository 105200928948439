import React from "react";
import { css } from "@emotion/core";
import { Link } from "gatsby";
import Image from 'gatsby-image';
import ReadLink from "../components/read-link";

const PostPreview = ({ post }) => (
  <article
    css={css`
      border-bottom: 1px solid #ddd;
      display: flex;
      margin-top: 0;
      padding-bottom: 1rem;
      :first-of-type {
        margin-top: 1rem;
      }2
    `}
  >
    <Link to={post.slug} 
      css={css` 
        margin: 1rem 1rem 0 0; 
        min-width: 125px;
      `}
    >
      <Image
        css={css`
          * {
            margin-top: 0;
          }
        `}
        fluid={post.image.sharp.fluid}
        alt={post.title}
      />
    </Link>
    <div>
      <h3>
        <Link to={post.slug} 
          css={css` 
            color: #000080;
            text-decoration: underline;
          `}
        >
          {post.title}
        </Link>
      </h3>

      <p>{post.excerpt}</p>
      
      <ReadLink 
        css={css` 
            {
              text-decoration: underline;
              color: #000080;
            }
            `}
          to={post.slug}>read this post &rarr;
        </ReadLink>

    </div>
  </article>
);

export default PostPreview;